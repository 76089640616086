
import { defineComponent, reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { PlusOutlined } from '@ant-design/icons-vue';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { fakeChartData, queryActivities, queryProjectNotice } from '@/api/dashboard/workplace';
import Radar from './components/radar/index.vue';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const state = reactive({
      loading: true,
      radarLoading: true,
      teams: [],
      // data
      axis1Opts: {
        dataKey: 'item',
        line: null,
        tickLine: null,
        grid: {
          lineStyle: {
            lineDash: null,
          },
          hideFirstLine: false,
        },
      },
      axis2Opts: {
        dataKey: 'score',
        line: null,
        tickLine: null,
        grid: {
          type: 'polygon',
          lineStyle: {
            lineDash: null,
          },
        },
      },
      scale: [
        {
          dataKey: 'score',
          min: 0,
          max: 80,
        },
      ],
      axisData: [
        { item: '引用', a: 70, b: 30, c: 40 },
        { item: '口碑', a: 60, b: 70, c: 40 },
        { item: '产量', a: 50, b: 60, c: 40 },
        { item: '贡献', a: 40, b: 50, c: 40 },
        { item: '热度', a: 60, b: 70, c: 40 },
        { item: '引用', a: 70, b: 50, c: 40 },
      ],
    });

    const { context: projects } = useFetchData(() => {
      return queryProjectNotice().then(res => {
        return {
          data: res,
          total: 100,
        };
      });
    });
    const { context: activities } = useFetchData(() => {
      return queryActivities().then(res => {
        return {
          data: res,
          total: 100,
        };
      });
    });

    const { context: radar } = useFetchData(() => {
      return fakeChartData().then(res => {
        return {
          data: res.radarData,
          total: 100,
        };
      });
    });

    return {
      t,
      state,
      currentUser: computed(() => store.getters[`user/currentUser`]),
      projects,
      activities,
      radar,
    };
  },
  components: {
    Radar,
    PlusOutlined,
  },
});
